import React, { Context, useContext, useMemo } from 'react';
import { RowContext, ColumnContext, TableContext } from './Contextes';
import { isReactElement, isReactFragment, isRowCell } from './utils';
import { NewDataTableBodyProps, NewDataTableRowCellProps, NewDataTableRowProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NewDataTableRow = <T,>(_props: NewDataTableRowProps<T>) => null;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NewDataTableRowCell = (_props: NewDataTableRowCellProps) => null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNestedValue = (obj: any, path: string): any =>
    path.split('.')?.reduce((acc, key) => (acc == null ? undefined : acc[key]), obj);

export const NewDataTableBody = <T,>({ children, data, getKey }: NewDataTableBodyProps<T>) => {
    const RowContextT = RowContext as Context<T>;
    const { orderedColumns } = useContext(TableContext);
    const cellDefinitions = useMemo(() => {
        const cellDefinitions: {
            rowClassName: null | ((row: T) => string);
            templates: Record<string, React.ReactNode>;
            default: React.ReactNode;
        } = {
            rowClassName: null,
            templates: {},
            default: null,
        };

        const processRow = (rowChild: React.ReactElement<NewDataTableRowProps<T>>) => {
            const core = (children: React.ReactNode) => {
                React.Children.forEach(children, child => {
                    if (isReactFragment(child)) {
                        core(child.props.children);
                    }

                    if (isRowCell(child)) {
                        if (child.props.default === true) {
                            cellDefinitions.default = child.props.children;
                        } else {
                            if (Array.isArray(child.props.id)) {
                                for (const id of child.props.id) {
                                    cellDefinitions.templates[id] = child.props.children;
                                }
                            } else {
                                cellDefinitions.templates[child.props.id] = child.props.children;
                            }
                        }
                    }
                });
            };

            core(rowChild.props.children);
            cellDefinitions.rowClassName = rowChild.props.rowClassName;
        };

        React.Children.forEach(children, child => {
            if (isReactElement(child)) {
                if (child.type === NewDataTableRow) {
                    processRow(child);
                }
            }
        });

        return cellDefinitions;
    }, [children]);

    return (
        <tbody>
            {data.map(r => {
                return (
                    <RowContextT.Provider value={r} key={getKey(r)}>
                        <tr className={cellDefinitions.rowClassName && cellDefinitions.rowClassName(r)}>
                            {orderedColumns.map(column => {
                                const val = getNestedValue(r, column) ?? '';
                                switch (typeof val) {
                                    case 'boolean':
                                        return (
                                            <td key={column} headers={column}>
                                                {val ? 'True' : 'False'}
                                            </td>
                                        );
                                    default:
                                        return (
                                            <td key={column} headers={column}>
                                                {cellDefinitions.templates[column] || cellDefinitions.default ? (
                                                    <ColumnContext.Provider value={column}>
                                                        {cellDefinitions.templates[column] ?? cellDefinitions.default}
                                                    </ColumnContext.Provider>
                                                ) : (
                                                    val
                                                )}
                                            </td>
                                        );
                                }
                            })}
                        </tr>
                    </RowContextT.Provider>
                );
            })}
        </tbody>
    );
};
