import { useContext } from 'react';
import useOrganizationModal from './useOrganizationModal';
import { ReactModal } from 'oemiq-common';
import ManageNetworksForOrgCompany from '../../ManageNetworksForOrgCompany/ManageNetworksForOrgCompany';
import { CustomerContext } from 'components/locations/ManageCustomers/ManageCustomersRoutes';
import { Select } from 'oemiq-common';
import 'components/locations/ManageCustomers/CustomerToolsStyles.scss';
import { ORG_CANCEL_BUTTON_ID } from '../../ManageCustomersConstants';
import { ClearableDropDown } from 'components/Shared';

const OrganizationModal = ({ orgId, isOpen, toggle, onSaveOrEdit, stagedCompId = null, segments }) => {
    const { countries } = useContext(CustomerContext);
    const {
        org,
        orgName,
        handleToggle,
        setOrgName,
        contactPersonId,
        setContactPersonId,
        orgUsers,
        address,
        setAddress,
        city,
        setCity,
        zip,
        setZip,
        state,
        setState,
        countryId,
        setCountryId,
        notes,
        setNotes,
        conciergeUrl,
        setConciergeUrl,
        inEditState,
        onDeleteClick,
        onAddNetwork,
        selectedNetwork,
        setSelectedNetwork,
        saveEnabled,
        handleSave,
        setSearchTerm,
        orgNameFeedbackMessage,
        segmentId,
        setSegmentId,
    } = useOrganizationModal(orgId, toggle, onSaveOrEdit, stagedCompId);

    return (
        <ReactModal
            id="create-or-edit-org"
            headerComponent={
                <div>
                    {orgId
                        ? 'Edit Organization'
                        : stagedCompId
                        ? 'Create Organization for Staged Company'
                        : 'Create new organization'}
                </div>
            }
            isOpen={isOpen}
            toggle={handleToggle}
            backdrop={inEditState ? 'static' : true}
            bodyComponent={
                <div>
                    <input
                        id="org-name"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="Organization Name (Required)"
                        value={orgName}
                        onChange={e => {
                            const entry = e.target.value;
                            setOrgName(entry);
                            setSearchTerm(entry);
                        }}
                    />
                    {orgNameFeedbackMessage(handleToggle)}
                    {orgId && (
                        <select
                            className="form-control mt-2"
                            value={contactPersonId}
                            onChange={e => setContactPersonId(parseInt(e.currentTarget.value))}>
                            <option value={-1}>Choose Contact Person</option>
                            {orgUsers &&
                                orgUsers.map(ou => {
                                    return (
                                        <option key={ou.userId} value={ou.userId}>
                                            {ou.firstName} {ou.lastName}
                                        </option>
                                    );
                                })}
                        </select>
                    )}
                    <input
                        id="org-address"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="Address (required)"
                        value={address}
                        onChange={e => setAddress(e.currentTarget.value)}
                    />
                    <input
                        id="org-city"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="City (required)"
                        value={city}
                        onChange={e => setCity(e.currentTarget.value)}
                    />
                    <input
                        id="org-zip"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="ZIP (required)"
                        value={zip}
                        onChange={e => setZip(e.currentTarget.value)}
                    />
                    <input
                        id="org-state"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="State (required)"
                        value={state}
                        onChange={e => setState(e.currentTarget.value)}
                    />
                    {countries && (
                        <Select
                            id="org-country"
                            className="form-select mt-2 w-100"
                            value={countryId}
                            options={countries}
                            defaultOption={{ value: -1, text: 'Select Country (required)' }}
                            onChange={e => setCountryId(parseInt(e.currentTarget.value))}
                        />
                    )}
                    {segments && (
                        <ClearableDropDown
                            id="org-segment"
                            className="form-select mt-2 w-100"
                            value={segmentId}
                            options={segments}
                            defaultOption={{ value: -1, text: 'Select Business Segment (Optional)' }}
                            onChange={e => setSegmentId(parseInt(e.currentTarget.value))}
                            onClear={() => setSegmentId(-1)}
                            fullWidth={true}
                        />
                    )}
                    <input
                        id="org-notes"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="Notes"
                        value={notes}
                        onChange={e => setNotes(e.currentTarget.value)}
                    />
                    <input
                        id="org-url"
                        className="form form-control mt-2"
                        type="text"
                        placeholder="Concierge Url"
                        value={conciergeUrl}
                        onChange={e => setConciergeUrl(e.currentTarget.value)}
                    />
                    {orgId && (
                        <ManageNetworksForOrgCompany
                            assignedNetworks={org.networks}
                            onDeleteClick={onDeleteClick}
                            onAddNetwork={onAddNetwork}
                            selectedNetwork={selectedNetwork}
                            setSelectedNetwork={setSelectedNetwork}
                            networkLevelIdFilter={1}
                        />
                    )}
                </div>
            }
            footerComponent={
                <div>
                    {inEditState && (
                        <div>
                            <button
                                type="button"
                                className="btn btn-danger h-100"
                                id={ORG_CANCEL_BUTTON_ID}
                                onClick={handleToggle}>
                                Cancel
                            </button>
                            <button
                                id="org-modal-save"
                                type="button"
                                className="btn btn-primary ms-3 h-100"
                                onClick={handleSave}
                                disabled={!saveEnabled}>
                                Save {`${stagedCompId ? 'and Next' : ''}`}
                            </button>
                        </div>
                    )}
                </div>
            }
        />
    );
};

export default OrganizationModal;
