import { FC } from 'react';
import {
    BasicEditValueComponent,
    BoolEditValueComponent,
    GroupEditValueComponent,
    GroupIdEditValueComponent,
} from './PropertyType.Components';
import {
    Boolean,
    Date,
    Number,
    BookId,
    BookName,
    Text,
    GroupList,
    TypeList,
    MappingStatus,
    BooleanDropdown,
} from 'components/Shared/TableFilters/Types';
import { Group, Type } from 'api/RepairProcedures/types';
import { ProcedureVehicle } from 'components/locations/MappingProcess/Mapper/ESProcedure';

type InputPropertyType = 'text' | 'number' | 'checkbox' | 'select' | 'bookId' | 'booleanDropdown';

export class PropertyType<TValue> {
    type: InputPropertyType;
    filterType: unknown;
    formatValue: (v: TValue) => TValue;
    editComponent: FC;

    constructor({
        type,
        filterType,
        formatValue,
        editComponent,
    }: {
        type: InputPropertyType;
        filterType: unknown;
        formatValue?: (v: TValue) => TValue;
        editComponent?: FC;
    }) {
        this.type = type;
        this.filterType = filterType;
        this.formatValue = formatValue ?? (v => v);
        this.editComponent = editComponent ?? BasicEditValueComponent;
    }
}

export const PROPERTY_TYPE = {
    string: new PropertyType<string>({
        type: 'text',
        filterType: Text,
        formatValue: v => `'${v}'`,
    }),
    number: new PropertyType<number>({
        type: 'number',
        filterType: Number,
    }),
    bookId: new PropertyType<number>({
        type: 'bookId',
        filterType: BookId,
    }),
    bookName: new PropertyType<number>({
        type: 'bookId',
        filterType: BookName,
    }),
    bool: new PropertyType<boolean>({
        type: 'checkbox',
        filterType: Boolean,
        editComponent: BoolEditValueComponent,
    }),
    booleanDropdown: new PropertyType<string>({
        type: 'text',
        filterType: BooleanDropdown,
    }),
    date: new PropertyType<string>({
        type: 'text',
        filterType: Date,
    }),
    vehicleView: new PropertyType<ProcedureVehicle[]>({
        type: 'text',
        filterType: Text,
    }),
    procedureView: new PropertyType<string>({
        type: 'text',
        filterType: Text,
        formatValue: v => `'${v}'`,
    }),
    historyView: new PropertyType<number>({
        type: 'number',
        filterType: Number,
    }),
    typeName: new PropertyType<Type[]>({
        type: 'select',
        filterType: TypeList,
    }),
    typeId: new PropertyType<number>({
        type: 'number',
        filterType: Number,
    }),
    groupView: new PropertyType<Group[]>({
        type: 'select',
        filterType: GroupList(false, true),
        editComponent: GroupEditValueComponent,
    }),
    groupsIds: new PropertyType<Group[]>({
        type: 'select',
        filterType: GroupList(true, true),
        editComponent: GroupIdEditValueComponent,
    }),
    mappingRuleGroupView: new PropertyType<Group[]>({
        type: 'select',
        filterType: GroupList(false, false),
        editComponent: GroupEditValueComponent,
    }),
    gmtDate: new PropertyType<string>({
        type: 'text',
        filterType: Date,
    }),
    mappingStatus: new PropertyType<string>({
        type: 'select',
        filterType: MappingStatus,
    }),
};
