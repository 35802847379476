import React, { useEffect, useMemo } from 'react';
import ReactSelect from 'react-select';
import { operatorsList } from '../operators';

const BooleanDropdown = ({ filter, setFilterValue }) => {
    useEffect(() => {
        if (typeof filter.value !== 'boolean') {
            setFilterValue({ value: false, valueList: ['false'] });
        }
    }, [filter.value, setFilterValue]);

    const options = useMemo(
        () => [
            { value: true, label: 'True' },
            { value: false, label: 'False' },
        ],
        []
    );

    const selectedOption = options.find(opt => opt.value === filter.value) || options[1];

    const handleChange = selected => {
        const val = selected.value;
        setFilterValue({ value: val, valueList: [val.toString()] });
    };

    return (
        <ReactSelect
            className="mb-2 basic-single"
            classNamePrefix="select"
            aria-label="Choose value"
            value={selectedOption}
            onChange={handleChange}
            options={options}
            isDisabled={!filter?.id || !filter?.operator}
        />
    );
};

export default {
    component: BooleanDropdown,
    operators: [operatorsList.eq],
    defaultValue: false,
    allowFalse: true,
    allowInstances: 1,
};
