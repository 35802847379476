import { operatorsList } from '../operators';
import BookIdFilter from './Helpers/Components/BookIdFilter';

export default {
    component: BookIdFilter,
    operators: [operatorsList.eqBookId, operatorsList.inBookId],
    defaultValue: 0,
    allowFalse: false,
    allowInstances: 1,
    validator: (value, operator) => {
        const isArray = Array.isArray(value);
        if (operator.value === operatorsList.inBookId.value) {
            return isArray ? value.length > 0 && value.every(x => !x.error) : value;
        }
        return isArray ? false : value;
    },
};
