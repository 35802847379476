import { operatorsList } from 'components/Shared/TableFilters/operators';
import { Number } from '../../Number';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { KeyboardEventHandler, useContext, useState } from 'react';
import { uuid } from 'helpers/UuidHelper';
import { OemNameMap } from 'helpers/OemsMap';
import { OemId } from 'helpers/OemId';
import { RpBookContext } from 'contexts/RpBookContext';
import { RpBookType } from 'types/RpBookType';
import { useParams } from 'react-router-dom';

interface BookIdFilterProps {
    filter;
    setFilterValue;
}

interface Option {
    label: string;
    value: string;
    id: string;
    error?: string | null;
}

interface ColorStyle {
    color: string;
    backgroundColor: string;
}

interface HoverStyle {
    ':hover': ColorStyle;
}

interface Styles {
    multiValueLabel: ColorStyle;
    multiValueRemove: ColorStyle & HoverStyle;
}

interface OptionWithStyles {
    option: Option;
    styles: Styles;
}
const inputId = 'book-id-filter-select';

const Input = props => <components.Input {...props} id={inputId} data-testid={inputId}></components.Input>;

const createOption = (value: string): Option => ({ id: uuid(), label: value, value });

const createStyles = (option: Option): Styles => {
    if (option.error) {
        return {
            multiValueLabel: {
                backgroundColor: 'rgba(255, 0, 0, 0.08)',
                color: 'red',
            },
            multiValueRemove: {
                backgroundColor: 'rgba(255, 0, 0, 0.08)',
                color: 'red',
                ':hover': {
                    backgroundColor: 'red',
                    color: 'white',
                },
            },
        };
    }
    return {
        multiValueLabel: {
            backgroundColor: 'rgba(0, 255, 0, 0.08)',
            color: 'green',
        },
        multiValueRemove: {
            backgroundColor: 'rgba(0, 255, 0, 0.08)',
            color: 'green',
            ':hover': {
                backgroundColor: 'green',
                color: 'white',
            },
        },
    };
};

const isPositiveInteger = (value: string) => {
    return /^\d+$/.test(value);
};

const validate = (options: Option[], rpBooks: RpBookType[], oemId: OemId): Option[] => {
    const validated: Option[] = [];
    for (const option of options) {
        const value = option.value;
        if (!isPositiveInteger(value)) {
            validated.push({ ...option, error: `'${value}' does not look like a book ID` });
        } else {
            const pi = parseInt(value);
            if (!rpBooks.find(x => x.bookId === pi)) {
                validated.push({ ...option, error: `'${value}' is not a ${OemNameMap[oemId]} book ID` });
            } else {
                const found = validated.find(x => x.value === value);
                if (found) {
                    validated.push({ ...option, error: `'${value}' is a duplicate` });
                } else {
                    validated.push({ ...option, error: null });
                }
            }
        }
    }
    return validated;
};

const BookIdFilter: React.FC<BookIdFilterProps> = ({ filter, setFilterValue }) => {
    const { rpBooks, isLoading } = useContext(RpBookContext);
    const [inputValue, setInputValue] = useState<string>('');
    const { oemId } = useParams();

    if (filter.operator.value !== operatorsList.inBookId.value) {
        return <Number filter={filter} setFilterValue={setFilterValue} />;
    }

    let value: Option[] = [];
    if (Array.isArray(filter.value)) {
        value = filter.value;
    } else {
        const parsed = parseInt(filter.value);
        if (!isNaN(parsed) && parsed) {
            // Initial value is a number (book id) so we artificially build an option to display
            // And we need deal with this case again when build the query
            value = [createOption(parsed.toString())];
        }
    }

    const updateValue = () => {
        const newOptions = inputValue
            .split(',')
            .map(x => x.trim())
            .filter(x => !!x)
            .map(x => createOption(x));

        const newValue = validate([...value, ...newOptions], rpBooks, parseInt(oemId));
        setFilterValue({ value: newValue, valueList: newValue.map(x => x.value) });

        setInputValue('');
    };

    const handleKeyDown: KeyboardEventHandler = e => {
        if (!inputValue) return;
        switch (e.key) {
            case 'Enter':
            case 'Tab': {
                updateValue();
                e.preventDefault();
            }
        }
    };

    const handleChange = (options: OptionWithStyles[]) => {
        const newValue = validate(
            options.map(x => x.option),
            rpBooks,
            parseInt(oemId)
        );
        setFilterValue({
            value: newValue,
            valueList: newValue.map(x => x.value),
        });
    };

    const valueWithStyles: OptionWithStyles[] = value.map(x => ({
        option: x,
        styles: createStyles(x),
    }));

    const errors = value.filter(x => !!x.error).map(x => x.error);

    return (
        <>
            <CreatableSelect
                components={{ DropdownIndicator: null, Input: Input }}
                menuIsOpen={false}
                isLoading={isLoading}
                isDisabled={isLoading}
                isClearable
                isMulti
                inputValue={inputValue}
                value={valueWithStyles}
                getOptionValue={x => x.option.id}
                getOptionLabel={x => x.option.label}
                onChange={handleChange}
                onInputChange={setInputValue}
                onKeyDown={handleKeyDown}
                onBlur={updateValue}
                placeholder="Type book ID or comma-separated book IDs and press enter"
                styles={{
                    multiValueLabel: (baseStyles, props) => ({
                        ...baseStyles,
                        ...props.data.styles.multiValueLabel,
                    }),
                    multiValueRemove: (baseStyles, props) => ({
                        ...baseStyles,
                        ...props.data.styles.multiValueRemove,
                        ':hover': {
                            ...props.data.styles.multiValueRemove[':hover'],
                        },
                    }),
                }}
            />
            {errors.length > 0 && (
                <div className="mt-2 overflow-auto" style={{ maxHeight: '20vh' }}>
                    <h6 className="text-danger sticky-top bg-white">Errors</h6>
                    <ul className="text-danger">
                        {errors.map((x, i) => (
                            <li key={i}>{x}</li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
};

export default BookIdFilter;
