import React, { useContext, useMemo, useState } from 'react';
import { Input, Select, InputSubmit, ReactModal } from 'oemiq-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactSelect from 'react-select';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import { useOemMetadataView } from 'hooks/OemModels/OemServices';
import { DataSource } from 'hooks/OemModels/MetaModel/DataSource';

const buildObjectWithRuleableFields = columns => {
    const ruleableFields = columns.reduce((obj, val) => {
        obj[val.fieldName] = '';
        return obj;
    }, {});
    return ruleableFields;
};

const ModalCreateMappingRule = ({
    oemId,
    isCreateRuleModalOpen,
    closeCreateRuleModalCallback,
    submitCallback,
    procedureType,
}) => {
    const { oemMetadataView } = useOemMetadataView(oemId, procedureType, DataSource.SQL);
    const columns = useMemo(() => {
        return oemMetadataView.ruleable.map(p => {
            const { rulePropertyName: fieldName, displayName: displayName } = oemMetadataView.metadata.properties[p];
            return { fieldName, displayName };
        });
    }, [oemMetadataView]);
    const [fields, setFields] = useState({
        ...buildObjectWithRuleableFields(columns),
        selectedGroups: [],
        typeId: -1,
    });
    const [isFormValid, setIsFormValid] = useState(false);

    const { groups, types } = useContext(MappingDefinitionsContext);

    const handleSubmit = e => {
        e.preventDefault();
        const values = {};
        columns.forEach(col => (values[col.fieldName] = fields[col.fieldName] ? fields[col.fieldName] : null));
        values['groupIds'] = fields.selectedGroups.map(group => group.value);
        values['typeId'] = fields.typeId !== -1 ? parseInt(fields.typeId, 10) : null;
        closeCreateRuleModalCallback();
        submitCallback(values);
    };

    const calculateValidity = fieldValues => {
        const ruleableValues = columns.reduce((arr, col) => {
            arr.push(fieldValues[col.fieldName]);
            return arr;
        }, []);
        const hasAtLeastOneRuleableValue = ruleableValues.some(v => v);
        const hasGroupOrType = fieldValues.typeId >= 0 || fieldValues.selectedGroups.length > 0;
        const isValidRule = hasAtLeastOneRuleableValue && hasGroupOrType;
        return isValidRule;
    };

    const handleOemFieldsChange = (value, valueName) => {
        const fieldValues = { ...fields, [valueName]: value };
        setFields(fieldValues);
        setIsFormValid(calculateValidity(fieldValues));
    };

    return (
        <ReactModal
            id="create-update-mapping-modal"
            isOpen={isCreateRuleModalOpen}
            toggle={closeCreateRuleModalCallback}
            headerComponent={<>Create New Mapping Rule for Procedures</>}
            bodyComponent={
                <form id="addEditMappingRuleForm" onSubmit={handleSubmit}>
                    {columns.map((column, idx) => (
                        <div key={idx} className="mb-2">
                            <Input
                                type="text"
                                className="form-control"
                                id={column.fieldName}
                                name={column.fieldName}
                                aria-describedby={column.displayName}
                                placeholder={column.displayName}
                                value={fields[column.fieldName]}
                                onChange={e => handleOemFieldsChange(e.currentTarget.value, column.fieldName)}
                            />
                        </div>
                    ))}
                    {groups && (
                        <ReactSelect
                            className="mt-2"
                            placeholder="Select Available Groups"
                            isMulti={true}
                            options={groups}
                            value={fields.selectedGroups}
                            onChange={e => handleOemFieldsChange(e, 'selectedGroups')}
                        />
                    )}
                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <div className="flex-fill">
                            <Select
                                formGroupClassName="mb-0"
                                id="ford-mapping-rule-type-select"
                                className="form-select"
                                value={fields.typeId}
                                onChange={e => handleOemFieldsChange(e.currentTarget.value, 'typeId')}
                                options={types}
                                defaultOption={{ text: 'Select Type', value: -1 }}
                            />
                        </div>
                        <div className="ms-4">
                            <button
                                type="button"
                                className="btn btn-sm btn-warning"
                                onClick={() => handleOemFieldsChange(-1, 'typeId')}>
                                <FontAwesomeIcon icon="times" />
                            </button>
                        </div>
                    </div>
                </form>
            }
            footerComponent={
                <div className="d-flex justify-content-end">
                    <InputSubmit
                        id={'create-or-update-mapping-rule'}
                        form={'addEditMappingRuleForm'}
                        value={'Create New Mapping Rule'}
                        disabled={!isFormValid}
                    />
                </div>
            }
        />
    );
};

export default ModalCreateMappingRule;
