import { requestBooksForOemApi } from 'api/RepairProcedureApi';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { OemIdMap } from 'helpers/OemsMap';
import { createContext, useContext, useEffect, useState } from 'react';
import { RpBookType } from 'types/RpBookType';

interface RpBookContext {
    rpBooks: RpBookType[];
    isLoading: boolean;
}

export const RpBookContext = createContext<RpBookContext | null>(null);

// Currently this is only used in new mapper which only displays books for a single major OEM (the selected one);
const RpBookContextProvider: React.FC<React.PropsWithChildren<{ oemId: number }>> = ({ oemId, children }) => {
    const { notifications } = useContext(NotificationsContext);
    const [rpBooks, setRpBooks] = useState<RpBookType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        let isUnmounted = false;
        const bookOems = OemIdMap.find(x => x.majorOem === oemId).bookOems;
        if (bookOems && bookOems.length > 0) {
            (async () => {
                try {
                    setIsLoading(true);
                    for (const id of bookOems) {
                        const books = await requestBooksForOemApi(id);
                        if (!isUnmounted) {
                            setRpBooks(prev => [...prev, ...books]);
                        } else {
                            break;
                        }
                    }
                } catch (e) {
                    notifications.pushExceptionDanger(e);
                } finally {
                    setIsLoading(false);
                }
            })();
        } else {
            setIsLoading(false);
            setRpBooks([]);
        }
        return () => {
            isUnmounted = true;
        };
    }, [oemId, notifications]);

    return <RpBookContext.Provider value={{ rpBooks, isLoading }}>{children}</RpBookContext.Provider>;
};

export default RpBookContextProvider;
