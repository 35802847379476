import { buildFilteredOemMetadata, OemMetadataView } from './MetaModel/BuildOemMetadata';
import { OemId } from 'helpers/OemId';
import { NISSAN_PROCEDURE_METADATA, NissanMappingEngineService } from './MetaModel/NissanProcedureMetadata';
import { TOYOTA_PROCEDURE_METADATA, ToyotaMappingEngineService } from './MetaModel/ToyotaProcedureMetadata';
import { FORD_PROCEDURE_METADATA, FordMappingEngineService } from './MetaModel/FordProcedureMetadata';
import { GM_PROCEDURE_METADATA, GMMappingEngineService } from './MetaModel/GMProcedureMetadata';
import { OEMIQ_FORD_PROCEDURE_METADATA, OemIqFordMappingEngineService } from './MetaModel/OemIqFordProcedureMetadata';
import { STELLANTIS_PROCEDURE_METADATA, StellantisMappingEngineService } from './MetaModel/StellantisProcedureMatadata';
import { VW_PROCEDURE_METADATA, VWMappingEngineService } from './MetaModel/VWProcedureMetadata';
import { SUBARU_PROCEDURE_METADATA, SubaruMappingEngineService } from './MetaModel/SubaruProcedureMatadata';
import { HYUNDAI_PROCEDURE_METADATA, HyundaiMappingEngineService } from './MetaModel/HyundaiProcedureMatadata';
import {
    FordProceduresApiService,
    OemIqFordProceduresApiService,
    ToyotaProceduresApiService,
    NissanProceduresApiService,
    GMProceduresApiService,
    HondaProceduresApiService,
    StellantisProceduresApiService,
    VWProceduresApiService,
    SubaruProceduresApiService,
    HyundaiProceduresApiService,
} from 'api/RepairProcedures/OemProceduresApiService';
import { match } from 'ts-pattern';
import { useMemo } from 'react';
import { ProcedureType } from './MetaModel/ProcedureType';
import { HONDA_PROCEDURE_METADATA, HondaMappingEngineService } from './MetaModel/HondaProcedureMetadata';
import { DataSource } from './MetaModel/DataSource';

export const OemEngineServices = {
    [OemId.Ford]: {
        oemEngineService: FordMappingEngineService,
        oemService: FordProceduresApiService,
    },
    [OemId.OEMiQ]: {
        oemEngineService: OemIqFordMappingEngineService,
        oemService: OemIqFordProceduresApiService,
    },
    [OemId.Toyota]: {
        oemEngineService: ToyotaMappingEngineService,
        oemService: ToyotaProceduresApiService,
    },
    [OemId.Nissan]: {
        oemEngineService: NissanMappingEngineService,
        oemService: NissanProceduresApiService,
    },
    [OemId.GMC]: {
        oemEngineService: GMMappingEngineService,
        oemService: GMProceduresApiService,
    },
    [OemId.Honda]: {
        oemEngineService: HondaMappingEngineService,
        oemService: HondaProceduresApiService,
    },
    [OemId.Chrysler]: {
        oemEngineService: StellantisMappingEngineService,
        oemService: StellantisProceduresApiService,
    },
    [OemId.Volkswagen]: {
        oemEngineService: VWMappingEngineService,
        oemService: VWProceduresApiService,
    },
    [OemId.Subaru]: {
        oemEngineService: SubaruMappingEngineService,
        oemService: SubaruProceduresApiService,
    },
    [OemId.Hyundai]: {
        oemEngineService: HyundaiMappingEngineService,
        oemService: HyundaiProceduresApiService,
    },
};

const oemToConf = (oemId: OemId, procedureType: ProcedureType | null, dataSource: DataSource) => {
    return match(oemId)
        .with(OemId.Ford, () => ({
            oemMetadataView: buildFilteredOemMetadata(FORD_PROCEDURE_METADATA, procedureType, dataSource),
        }))
        .with(OemId.OEMiQ, () => ({
            oemMetadataView: buildFilteredOemMetadata(OEMIQ_FORD_PROCEDURE_METADATA, procedureType, dataSource),
        }))
        .with(OemId.Toyota, () => ({
            oemMetadataView: buildFilteredOemMetadata(TOYOTA_PROCEDURE_METADATA, procedureType, dataSource),
        }))
        .with(OemId.Nissan, () => ({
            oemMetadataView: buildFilteredOemMetadata(NISSAN_PROCEDURE_METADATA, procedureType, dataSource),
        }))
        .with(OemId.GMC, () => ({
            oemMetadataView: buildFilteredOemMetadata(GM_PROCEDURE_METADATA, procedureType, dataSource),
        }))
        .with(OemId.Honda, () => ({
            oemMetadataView: buildFilteredOemMetadata(HONDA_PROCEDURE_METADATA, procedureType, dataSource),
        }))
        .with(OemId.Chrysler, () => ({
            oemMetadataView: buildFilteredOemMetadata(STELLANTIS_PROCEDURE_METADATA, procedureType, dataSource),
        }))
        .with(OemId.Volkswagen, () => ({
            oemMetadataView: buildFilteredOemMetadata(VW_PROCEDURE_METADATA, procedureType, dataSource),
        }))
        .with(OemId.Subaru, () => ({
            oemMetadataView: buildFilteredOemMetadata(SUBARU_PROCEDURE_METADATA, procedureType, dataSource),
        }))
        .with(OemId.Hyundai, () => ({
            oemMetadataView: buildFilteredOemMetadata(HYUNDAI_PROCEDURE_METADATA, procedureType, dataSource),
        }))
        .otherwise(() => ({ oemMetadataView: null }));
};

export const useOemMetadataView = (
    oemId: number | string,
    procedureType: ProcedureType | null = null,
    dataSource = DataSource.SQL
): {
    oemMetadataView: OemMetadataView;
} => {
    const oemIdAsNumber = typeof oemId === 'number' ? oemId : parseInt(oemId, 10);
    return useMemo(
        () => oemToConf(oemIdAsNumber as OemId, procedureType, dataSource),
        [dataSource, procedureType, oemIdAsNumber]
    );
};
