import { useState, useEffect } from 'react';
import useModal from 'hooks/useModal';
import PreviewProceduresModal from './PreviewProceduresModal';
import { OemMetadataView } from 'hooks/OemModels/MetaModel/BuildOemMetadata';
import { OemEngineServices } from 'hooks/OemModels/OemServices';
import { FilterClause } from '../../Mapper/MapperTool';
import useColumnSettings from '../../Mapper/useColumnSettings';

import { PrimaryButton } from 'oemiq-common';
import { RuleableOperatorBackendEnumValue } from '../RulableValuePicker/types';
import { operatorsList } from 'components/Shared/TableFilters/operators';
import { CreatorFilter } from '../types';
import { Text } from 'components/Shared/TableFilters/Types';

export const translateMetadataFilters = (filter: CreatorFilter): FilterClause => {
    return {
        id: filter.propertyName,
        property: filter.propertyName,
        operator: convertOperators(filter.operator),
        value: filter.value,
        type: Text,
        key: filter.propertyName,
    };
};

const previewProceduresColumnsIds = [
    'procedureId',
    'firstBookId',
    'firstBookName',
    'procedureTitle',
    'groups',
    'groupsView',
    'TypeId',
    'TypeName',
];

interface PreviewProceduresProps {
    filters: FilterClause[];
    oemMetadataView: OemMetadataView;
    oemId: number;
}

const PreviewProcedures = ({ filters, oemMetadataView, oemId }: PreviewProceduresProps) => {
    const { oemService } = OemEngineServices[oemId];
    const [totalCount, setTotalCount] = useState(null);
    useEffect(() => {
        async function countProcedures() {
            const count = await oemService.countSQLProcedures(filters, null);
            setTotalCount(count);
        }

        setTotalCount(null);
        filters && countProcedures();
    }, [filters, oemService]);

    const { columnSettings } = useColumnSettings('MappingEngineTool', oemMetadataView.metadata.properties);
    const previewProceduresColumnSettings = columnSettings.filter(x => previewProceduresColumnsIds.includes(x.id));

    const {
        isModalOpen: isPreviewProceduresModalOpen,
        openModal: openPreviewProceduresModal,
        closeModal: closePreviewProceduresModal,
    } = useModal();

    const handleSorting = () => {
        return null;
    };

    return (
        <div>
            <PrimaryButton
                type="button"
                className="show-procedures-button me-3"
                disabled={totalCount === null}
                onClick={openPreviewProceduresModal}>
                {totalCount === null ? '...' : `${totalCount} Procedures`}
            </PrimaryButton>
            <div>
                {isPreviewProceduresModalOpen && (
                    <PreviewProceduresModal
                        filters={filters}
                        oemId={oemId}
                        oemMetadataView={oemMetadataView}
                        isPreviewProceduresModalOpen={isPreviewProceduresModalOpen}
                        closePreviewProceduresModal={closePreviewProceduresModal}
                        columnSettings={previewProceduresColumnSettings}
                        handleSorting={handleSorting}
                        totalCount={totalCount}
                    />
                )}
            </div>
        </div>
    );
};

const convertOperators = (operator: RuleableOperatorBackendEnumValue) => {
    switch (operator) {
        case RuleableOperatorBackendEnumValue.Equal:
            return operatorsList.eq;
        case RuleableOperatorBackendEnumValue.Contains:
            return operatorsList.contains;
        case RuleableOperatorBackendEnumValue.NotContains:
            return operatorsList.notContains;
        default:
            return operatorsList.eq;
    }
};

export default PreviewProcedures;
