import { Buttons, LocalDate, Number, Labels } from 'components/Shared/Table/Cells';
import ActionList from './ActionList';
import { Header, LabelWithPills } from 'components/Shared/Table/types';
import RuleConditionLabels, { RuleConditionItem } from './RuleConditionLabels/RuleConditionLabels';
import { OemMetadataView } from 'hooks/OemModels/MetaModel/BuildOemMetadata';

type RuleStatistics = { procedureCount?: number };

export const buildTableConfiguration = (
    oemMetadataView: OemMetadataView,
    hasAccess: (resource: string) => boolean
): Header[] => {
    return [
        {
            label: 'Id',
            id: oemMetadataView.metadata.ruleKey,
            component: Number(),
        },
        {
            label: 'Number of mapped procedures',
            id: 'view',
            component: Buttons({
                buttons: [
                    {
                        id: 'handleNavigateToEngine',
                        title: 'Navigate to Procedures',
                        class: 'btn-primary',
                        component: i => (
                            <span>
                                {i.isRunned ? (i.statistics as RuleStatistics)?.procedureCount ?? 0 : 'Not run yet'}
                            </span>
                        ),
                        show: () => true,
                        disabled: () => false,
                    },
                ],
            }),
        },
        {
            label: 'Rule Conditions',
            id: 'ruleConditions',
            component: RuleConditionLabels({
                labels: i => i.labeledMetadata as RuleConditionItem[],
            }),
        },
        {
            label: 'Groups',
            id: 'groups',
            component: Labels({
                labelClass: 'text-bg-primary',
                labels: i => i.labeledGroups as LabelWithPills[],
            }),
        },
        {
            label: 'Type',
            id: 'type',
            component: Labels({
                labelClass: 'text-bg-info',
                labels: i => i.labeledType as LabelWithPills[],
            }),
        },
        {
            label: 'Create Date',
            id: 'createDate',
            component: LocalDate(),
        },
        {
            label: 'Actions',
            id: 'actions',
            component: ActionList({
                undoButton: {
                    id: 'handleUndoRule',
                    class: 'btn-primary',
                    text: 'Undo',
                    title: !hasAccess('rule.undo') ? 'You are not authorized to undo a rule' : 'Undo Mapping Rule',
                    disabled: rule => !hasAccess('rule.undo') || !rule.isRunned,
                },
                removeButton: {
                    id: 'handleRemoveRule',
                    class: 'btn-primary',
                    text: 'Remove',
                    title: !hasAccess('rule.undo') ? 'You are not authorized to remove a rule' : 'Remove Mapping Rule',
                    disabled: rule =>
                        !hasAccess('rule.remove') ||
                        ((rule.isRunned as boolean) && (rule.statistics as RuleStatistics)?.procedureCount > 0),
                },
                slider: {
                    id: 'handleActivation',
                    checkedTitle: 'Mapping rule is active',
                    uncheckedTitle: 'Mapping rule is inactive',
                    checked: rule => rule.isActive as boolean,
                    disabled: rule => !hasAccess('rule.toggleActive') || (rule.IsDeleted as boolean),
                },
            }),
        },
    ];
};
