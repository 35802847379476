import { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import { PrimaryButton } from 'oemiq-common';
import { NotificationsContext } from 'components/Shared/Notifications/Notifications';
import { OemEngineServices, useOemMetadataView } from 'hooks/OemModels/OemServices';
import useModal from 'hooks/useModal';
import useProcedures from 'components/OemProceduresTable/items/useProcedures';
import useColumnSettings from './useColumnSettings';
import useSorting from './useSorting';
import ColumnSettings from 'components/locations/MappingProcess/OldMapper/Actions/ColumnSettings/ColumnSettings';
import BuildMappingRuleModal from './BuildMappingRuleModal/BuildMappingRuleModal';
import OemProceduresTable from 'components/OemProceduresTable/OemProceduresTable';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccessControlContext } from 'components/Shared/AccessControl/AccessControl';
import { MappingDefinitionsContext } from 'contexts/MappingDefinitionsContext';
import TableFilters from 'components/Shared/TableFilters/TableFilters';
import buildFiltersConfiguration from './buildFiltersConfiguration';
import ProcedureFilterCheckboxes from './MappingRuleFilters/ProcedureFilterCheckboxes';
import { isFilterRulable } from 'components/Shared/TableFilters/operators';
import { createFiltersFromSearchParams } from 'components/Shared/TableFilters/tableFilterHelpers';
import ViewProcedureModal from 'components/Modals/ViewProcedureModal/ViewProcedureModal';
import ViewProcedureMappingHistoryModal from 'components/Modals/ViewProcedureMappingHistoryModal/ViewProcedureMappingHistoryModal';
import { useSearchParams } from 'react-router-dom';
import SmallSpinner from 'components/SmallSpinner';
import useBulkSelect from '../../../../hooks/useBulkSelect';
import { SingleGroupTypeModal } from 'components/locations/MappingProcess/OldMapper/OldMapperTable/GroupTypeModal/GroupTypeModal';
import { OlsMapperListContext } from 'contexts/MapperListContext';
import BulkMoveModal from '../OldMapper/OldMapperTable/BulkMoveModal/BulkMoveModal';
import GroupTypeModal from 'components/locations/MappingProcess/OldMapper/OldMapperTable/GroupTypeModal/GroupTypeModal';
import BulkTaskHistoryModal from '../OldMapper/BulkTaskHistoryModal';
import { ESProcedure } from './ESProcedure';
import { LoadingContext } from 'components/Layout';
import { ToggleButton, ButtonGroup } from 'react-bootstrap';
import { ProcedureType } from 'hooks/OemModels/MetaModel/ProcedureType';
import ProcedureTypeFilter from 'components/Shared/TableFilters/Types/ProcedureTypeFilter';
import { ProcedureTypeFilterProperty } from 'hooks/OemModels/MetaModel/BaseProcedureMetadata';
import { useStateFromProp } from '../../../Shared/useStateFromProp';
import { VehicleInfoProvider } from 'contexts/VehicleInfoContext';
import { FilteredVehicle } from 'hooks/OemModels/MetaModel/types/FilteredVehicle';
import { getFilteredVehicles } from './logic/getFilteredVehicles';
import { DataSource } from 'hooks/OemModels/MetaModel/DataSource';
import './mapperTool.scss';
import RpBookContextProvider from 'contexts/RpBookContext';

const ES_BULK_LIMIT = 10000;

const defaultFilterCheckboxes = {
    filterNoGroups: false,
    filterNoType: false,
    filterRefreshedPending: false,
    filterOnlyHotSheet: false,
    filterRemovedRemovalPending: false,
};

export type FilterCheckboxes = typeof defaultFilterCheckboxes;

export type FilterClause = {
    id: string;
    operator: { label: string; value: string };
    value;
    property: string; // -> it's name from metadata.property
    type: unknown;
    key: string; // only for TableFilters TODO to clean up filter component
    isAppOnly?: boolean; // only for TableFilters TODO to clean up filter component
};

const createProcedureTypeFilterClause = (procedureType: ProcedureType): FilterClause => {
    return {
        ...ProcedureTypeFilterProperty,
        key: '',
        operator: ProcedureTypeFilter.operators[0],
        value: procedureType,
        isAppOnly: true,
    };
};

const MapperTool = ({ defaultProcedureType }: { defaultProcedureType?: ProcedureType }) => {
    const { notifications } = useContext(NotificationsContext);
    const { incrementLoading, decrementLoading } = useContext(LoadingContext);
    const { hasAccess } = useContext(AccessControlContext);

    const { oemId } = useParams();
    const routeParams = useLocation();
    const [searchParams] = useSearchParams();
    const searchParamsObject = useMemo(() => Object.fromEntries(searchParams), [searchParams]);
    const navigate = useNavigate();

    const [dataSource, setDataSource] = useState<DataSource>(DataSource.ES);

    const { oemEngineService, oemService } = OemEngineServices[oemId];
    const { oemMetadataView } = useOemMetadataView(oemId, defaultProcedureType, dataSource);

    const [errorMessage, setErrorMessage] = useState(null);
    const filtersConfiguration = buildFiltersConfiguration(oemMetadataView);
    const [newFilters, setNewFilters] = useStateFromProp<FilterClause[]>(
        current => {
            if (current) {
                const index = current.findIndex(f => f.property === ProcedureTypeFilterProperty.property);
                if (index !== -1) {
                    current.splice(index, 1);
                }

                current = current.filter(
                    fc => oemMetadataView.properties.includes(fc.id) || oemMetadataView.filters.includes(fc.id)
                );

                if (defaultProcedureType) {
                    return [createProcedureTypeFilterClause(defaultProcedureType), ...current];
                }

                return [...current];
            }
            const filtersFromSearchParams = routeParams.search
                ? [...createFiltersFromSearchParams(searchParamsObject, filtersConfiguration)]
                : [];

            if (defaultProcedureType) {
                return [createProcedureTypeFilterClause(defaultProcedureType), ...filtersFromSearchParams];
            }

            return filtersFromSearchParams;
        },
        [defaultProcedureType]
    );

    const [bulkOperationsProcedureIds, setBulkOperationsProcedureIds] = useState([]);
    const mappingDefinitions = useContext(MappingDefinitionsContext);

    if (!oemMetadataView) {
        !errorMessage && setErrorMessage('Oem selected does not currently support mapping engine functionality');
    }

    const handleDataSourceChangeToSQL = useCallback(() => {
        const ind = newFilters.findIndex(f => f.property === 'ProcedureHTML');
        if (ind !== -1) {
            setNewFilters(current => [...current.filter((_f, i) => i !== ind)]);
        }
        setDataSource(DataSource.SQL);
    }, [newFilters, setNewFilters]);

    const handleDataSourceChangeToES = useCallback(() => {
        setDataSource(DataSource.ES);
    }, []);

    const handleCreateMappingRule = useCallback(
        async rule => {
            try {
                await oemEngineService.createRule(rule);
                notifications.pushSuccess('New mapping rule created');
            } catch (e) {
                notifications.pushExceptionDanger(e);
            }
        },
        [notifications, oemEngineService]
    );

    const { orderBy, handleSorting } = useSorting();

    const [filterCheckboxes, setFilterCheckboxes] = useState<FilterCheckboxes>(defaultFilterCheckboxes);

    const {
        data,
        hasMoreData,
        loading: isLoadingProcedures,
        totalCount,
        loadMoreCallback,
        refreshProcedures,
        handleReloadButton,
        updateOemIqTypeForProcedureIds,
        updateProcedures,
        setNewGroupListToProcedureByProcedureId,
        getProcedureIds,
    } = useProcedures(
        newFilters,
        filterCheckboxes,
        orderBy,
        oemId,
        filterCheckboxes.filterRemovedRemovalPending,
        dataSource
    );

    const { columnSettings, handleSaveColumnSettings, setShowColumnSettings, showColumnSettings } = useColumnSettings(
        `MappingEngineTool${oemMetadataView.key.columnSettingsPostfix}`,
        useMemo(() => oemMetadataView.properties.map(p => oemMetadataView.metadata.properties[p]), [oemMetadataView])
    );

    const canCreateRule = () => {
        if (
            newFilters.find(f => f.property === ProcedureTypeFilterProperty.property)?.value !== ProcedureType.Procedure
        )
            return false;

        const filters = newFilters.filter(f => f.property && f.property !== ProcedureTypeFilterProperty.property);
        const ruleableFilters = filters.filter(isFilterRulable);
        const uniqueFilterCount = new Set(filters.map(f => f.property)).size;
        return (
            filters.length > 0 &&
            !filterCheckboxes.filterRefreshedPending &&
            !filterCheckboxes.filterNoGroups &&
            !filterCheckboxes.filterNoType &&
            ruleableFilters.length === filters.length &&
            ruleableFilters.length === uniqueFilterCount
        );
    };

    const navigateToRules = useCallback(() => {
        const filters = newFilters
            .filter(f => oemMetadataView.metadata.properties[f.property].ruleable)
            .map(f => ({
                id: oemMetadataView.metadata.properties[f.property].rulePropertyName,
                operator: f.operator.value,
                value: f.value,
            }));

        navigate(`/mapping-process/rules-runner/${oemId}`, { state: { filters } });
    }, [newFilters, navigate, oemId, oemMetadataView.metadata.properties]);

    const [isCreateMappingRuleModalOpen, setCreateMappingRuleModalOpen] = useState<boolean>(false);
    const [modalProcedure, setModalProcedure] = useState<ESProcedure | undefined>();
    const [historyModalProcedure, setHistoryModalProcedure] = useState(null);

    const isRemovalPending = modalProcedure?.stageArea.isDeleted && !modalProcedure.isDeleted;
    const isRemoved = modalProcedure?.stageArea.isDeleted && modalProcedure.isDeleted;

    const {
        isModalOpen: isSingleGroupTypeModalOpen,
        openModal: openSingleGroupTypeModal,
        closeModal: closeSingleGroupTypeModal,
    } = useModal();

    const {
        isModalOpen: isBulkMoveModalOpen,
        openModal: openBulkMoveModal,
        closeModal: closeBulkMoveModal,
    } = useModal();

    const {
        isModalOpen: isGroupTypeModalOpen,
        openModal: openGroupTypeModal,
        closeModal: closeGroupTypeModal,
    } = useModal();

    const [groupTypeModalBulkType, setGroupTypeModalBulkType] = useState(null);

    const handleOpenBulkAssignModal = useCallback(() => {
        setGroupTypeModalBulkType('Assign');
        openGroupTypeModal();
    }, [openGroupTypeModal, setGroupTypeModalBulkType]);

    const handleOpenBulkRemoveModal = useCallback(() => {
        setGroupTypeModalBulkType('Remove');
        openGroupTypeModal();
    }, [openGroupTypeModal, setGroupTypeModalBulkType]);

    const [isBulkActionHistoryModalOpen, setIsBulkActionHistoryModalOpen] = useState(false);

    const handleBulkTaskHistoryButtonClick = useCallback(() => {
        setIsBulkActionHistoryModalOpen(true);
    }, []);

    const handleBulkTaskHistoryToggle = useCallback(() => {
        setIsBulkActionHistoryModalOpen(prev => !prev);
    }, []);

    const contextValue = useMemo(() => {
        return {
            refreshProcedures,
            updateProcedures,
            updateOemIqTypeForProcedureIds,
            setNewGroupListToProcedureByProcedureId,
            toggleBulkTaskHistoryModal: handleBulkTaskHistoryToggle,
        };
    }, [
        refreshProcedures,
        updateProcedures,
        updateOemIqTypeForProcedureIds,
        setNewGroupListToProcedureByProcedureId,
        handleBulkTaskHistoryToggle,
    ]);

    const { selected, isAll, resetSelected, handleSelectAll, handleSelectOne } = useBulkSelect(
        totalCount.value,
        'procedureId',
        true
    );

    // ----------------------------------------------------------------------
    // Expandable Procedure Vehicles

    const [expandedProcedures, setExpandedProcedures] = useState<number[]>([]);

    const handleExpandProcedureClick = (procedureId: number) => {
        setExpandedProcedures(prev =>
            prev.includes(procedureId) ? prev.filter(item => item !== procedureId) : [...prev, procedureId]
        );
    };

    const filteredVehicles = useMemo<FilteredVehicle[]>(() => getFilteredVehicles(newFilters), [newFilters]);

    // ----------------------------------------------------------------------

    useEffect(() => {
        const fetchProcedures = async () => {
            try {
                incrementLoading();
                const procedureIds = await getProcedureIds(ES_BULK_LIMIT, 0);
                setBulkOperationsProcedureIds(procedureIds);
            } catch (error) {
                setErrorMessage(error);
            } finally {
                decrementLoading();
            }
        };

        if (dataSource === DataSource.ES && isAll == true) {
            if (totalCount.value > ES_BULK_LIMIT) {
                notifications.pushSuccess(
                    'The requested operation will only be performed on 10k procedures. Please repeat the operation for the remaining procedures'
                );
            }

            fetchProcedures();
        }
    }, [isAll, getProcedureIds, incrementLoading, decrementLoading, totalCount.value, notifications, dataSource]);

    const selectedProceduresCount = isAll
        ? dataSource === DataSource.ES
            ? bulkOperationsProcedureIds.length
            : totalCount.selectableCount
        : selected.length;

    return (
        <VehicleInfoProvider>
            <OlsMapperListContext.Provider value={contextValue}>
                <RpBookContextProvider oemId={parseInt(oemId)}>
                    <div className="container-main pt-3 pe-3 ps-3 d-flex flex-column">
                        <div className="d-flex">
                            <h2 className="flex-grow-1">Mapper</h2>
                            <ButtonGroup className="me-3" aria-label="Switch Elastic-SQL">
                                <ToggleButton
                                    value="esButton"
                                    id="ESProcedureModeButton"
                                    title="Elasticsearch"
                                    className="py-2 px-4"
                                    type="radio"
                                    name="radio"
                                    variant={dataSource === DataSource.ES ? 'primary' : 'outline-primary'}
                                    checked={dataSource === DataSource.ES}
                                    onChange={handleDataSourceChangeToES}>
                                    Elasticsearch
                                </ToggleButton>
                                <ToggleButton
                                    value="sqlButton"
                                    id="SQLProcedureModeButton"
                                    title="SQL"
                                    className="py-2 px-4"
                                    type="radio"
                                    name="radio"
                                    variant={dataSource === DataSource.SQL ? 'primary' : 'outline-primary'}
                                    checked={dataSource === DataSource.SQL}
                                    onChange={handleDataSourceChangeToSQL}>
                                    SQL
                                </ToggleButton>
                            </ButtonGroup>
                            <PrimaryButton
                                id="bulk-task-history-button"
                                type="button"
                                className="me-3"
                                onClick={handleBulkTaskHistoryButtonClick}>
                                Bulk Task History
                            </PrimaryButton>
                            <PrimaryButton
                                id="link-goto-view-rules"
                                type="button"
                                className="me-3"
                                onClick={navigateToRules}>
                                View Rules <FontAwesomeIcon className="ms-1" icon="arrow-up-right-from-square" />
                            </PrimaryButton>
                            <PrimaryButton
                                id="btn-modal-create-mapping-rule"
                                className="me-3"
                                type="button"
                                disabled={!canCreateRule() || !hasAccess('rule.create')}
                                onClick={() => setCreateMappingRuleModalOpen(true)}>
                                Create New Mapping Rule
                            </PrimaryButton>
                            <PrimaryButton
                                id="column-settings"
                                type="button"
                                onClick={() => setShowColumnSettings(true)}>
                                Column Settings
                            </PrimaryButton>
                        </div>

                        <div className="text-danger">{errorMessage && errorMessage}</div>

                        <TableFilters
                            configuration={filtersConfiguration}
                            filters={newFilters}
                            setFilters={setNewFilters}
                            onReloadButtonClick={handleReloadButton}
                        />

                        <ProcedureFilterCheckboxes
                            filterCheckboxes={filterCheckboxes}
                            setFilterCheckboxes={setFilterCheckboxes}
                        />

                        {isSingleGroupTypeModalOpen && (
                            <SingleGroupTypeModal
                                isOpen={isSingleGroupTypeModalOpen}
                                procedure={modalProcedure}
                                closeGroupTypeModal={closeSingleGroupTypeModal}
                                setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                            />
                        )}

                        <ViewProcedureModal
                            procedureId={modalProcedure?.procedureId || null}
                            resetProcedureId={() => setModalProcedure({ ...modalProcedure, procedureId: null })}
                            isRemoved={isRemovalPending || isRemoved}
                            headerButtons={
                                <div style={{ marginTop: -6, marginBottom: -5 }}>
                                    <PrimaryButton
                                        className="btn-sm"
                                        id="map-procedure"
                                        type="button"
                                        disabled={isRemovalPending || isRemoved}
                                        onClick={openSingleGroupTypeModal}>
                                        Map Procedures
                                    </PrimaryButton>
                                </div>
                            }
                        />

                        <ViewProcedureMappingHistoryModal
                            procedure={historyModalProcedure}
                            resetProcedureId={() => setHistoryModalProcedure(null)}
                        />

                        <OemProceduresTable
                            data={data}
                            hasMoreData={hasMoreData}
                            oemMetadataView={oemMetadataView}
                            columnSettings={columnSettings}
                            isLoading={isLoadingProcedures}
                            filteredVehicles={filteredVehicles}
                            onSorting={handleSorting}
                            loadMoreCallback={loadMoreCallback}
                            onClickModalProcedure={setModalProcedure}
                            onclickHistoryModalProcedure={setHistoryModalProcedure}
                            refreshProcedures={refreshProcedures}
                            setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                            isEditable={true}
                            selected={selected}
                            isAll={isAll}
                            onSelectAll={handleSelectAll}
                            onSelectOne={handleSelectOne}
                            expandedProcedures={expandedProcedures}
                            onExpandProcedureClick={handleExpandProcedureClick}
                        />

                        <footer id="status-bar" className="m-1">
                            <div className="d-flex">
                                <div className="d-flex flex-grow-1 align-items-center">
                                    <TotalCount
                                        totalCount={totalCount}
                                        current={data.length}
                                        selectedCount={selectedProceduresCount}
                                    />
                                </div>
                                <div className="flex-row-reverse">
                                    {(selected.length >= 1 || isAll) && (
                                        <div className="mt-3 mb-3">
                                            <span className="pe-2 pb-1">
                                                <PrimaryButton
                                                    id="bulk-assign-procedures"
                                                    type="button"
                                                    disabled={selected.length <= 1 && !isAll}
                                                    onClick={handleOpenBulkAssignModal}>
                                                    Bulk Assign
                                                </PrimaryButton>
                                            </span>
                                            <span className="pe-2 pb-1">
                                                <PrimaryButton
                                                    id="bulk-unassign-procedures"
                                                    type="button"
                                                    disabled={selected.length <= 1 && !isAll}
                                                    onClick={handleOpenBulkRemoveModal}>
                                                    Bulk Remove
                                                </PrimaryButton>
                                            </span>
                                            <span className="pe-2 pb-1">
                                                <PrimaryButton
                                                    id="bulk-move-procedures"
                                                    type="button"
                                                    onClick={openBulkMoveModal}>
                                                    Bulk Move
                                                </PrimaryButton>
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </footer>

                        {isGroupTypeModalOpen && (
                            <GroupTypeModal
                                isOpen={isGroupTypeModalOpen}
                                groupTypeModalProcedureIds={isAll ? bulkOperationsProcedureIds : selected}
                                selectedProceduresCount={selectedProceduresCount}
                                resetProcedureIds={closeGroupTypeModal}
                                setNewGroupListToProcedureByProcedureId={setNewGroupListToProcedureByProcedureId}
                                procedures={isAll ? data : data.filter(p => selected.includes(p.procedureId))}
                                resetBulkSelection={resetSelected}
                                groupTypeModalBulkType={groupTypeModalBulkType}
                                setGroupTypeModalBulkType={setGroupTypeModalBulkType}
                                oemId={parseInt(oemId)}
                                oDataFilter={
                                    isAll && dataSource === DataSource.SQL
                                        ? oemService.buildSQLFilter(newFilters, filterCheckboxes)
                                        : null
                                }
                            />
                        )}

                        {isBulkMoveModalOpen && (
                            <BulkMoveModal
                                isOpen={isBulkMoveModalOpen}
                                statusModalProcedureIds={isAll ? bulkOperationsProcedureIds : selected}
                                selectedProceduresCount={selectedProceduresCount}
                                resetProcedureIds={closeBulkMoveModal}
                                resetBulkSelection={resetSelected}
                                procedures={isAll ? data : data.filter(p => selected.includes(p.procedureId))}
                                oDataFilter={
                                    isAll && dataSource === DataSource.SQL
                                        ? oemService.buildSQLFilter(newFilters, filterCheckboxes)
                                        : null
                                }
                            />
                        )}

                        {isBulkActionHistoryModalOpen && (
                            <BulkTaskHistoryModal
                                isOpen={isBulkActionHistoryModalOpen}
                                onToggle={handleBulkTaskHistoryToggle}
                            />
                        )}

                        <BuildMappingRuleModal
                            isModalOpen={isCreateMappingRuleModalOpen}
                            setModalOpen={setCreateMappingRuleModalOpen}
                            filters={newFilters.filter(f => f.property)}
                            mappingDefinitions={mappingDefinitions}
                            proceduresCount={totalCount}
                            oemMetadata={oemMetadataView.metadata}
                            onCreateMappingRule={handleCreateMappingRule}
                        />

                        <ColumnSettings
                            showColumnSettings={showColumnSettings}
                            setShowColumnSettings={setShowColumnSettings}
                            hideDynamicKeyList={[]}
                            dynamicProcedureKeys={columnSettings}
                            onSaveColumnSettings={handleSaveColumnSettings}
                        />
                    </div>
                </RpBookContextProvider>
            </OlsMapperListContext.Provider>
        </VehicleInfoProvider>
    );
};

type TotalCountProps = {
    totalCount: { error: boolean; value: number; loadingCount: boolean; selectableCount: number };
    current: number;
    selectedCount: number;
};

const TotalCount = ({ totalCount, current, selectedCount }: TotalCountProps) => {
    if (totalCount.loadingCount) {
        return <SmallSpinner />;
    }

    if (totalCount.error) {
        return <span className="total-count-error">Unable to load total count (loaded {current} procedures)</span>;
    }

    return (
        <>
            {current} procedures of {totalCount.value} ({selectedCount} selected)
        </>
    );
};

export default MapperTool;
