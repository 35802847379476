const escapeSpecialChars = str => {
    if (typeof str !== 'string' && !(str instanceof String)) return str;

    const specialChars = /[+\-=&|><!(){}[\]^"~*?:\\/\s@#]/g;
    return str.replace(specialChars, '\\$&');
};

export const ManuallyMapped = {
    INCLUDE: 'include',
    EXCLUDE: 'exclude',
};

const operatorsList = {
    contains: {
        value: 'contains',
        label: 'contains',
    },
    containsBookName: {
        value: 'containsbookname',
        label: 'contains',
    },
    notContains: {
        value: 'notContains',
        label: 'not contains',
    },
    notContainsBookName: {
        value: 'notcontainsbookname',
        label: 'not contains',
    },
    eq: {
        value: 'eq',
        label: '==',
    },
    eqBookId: {
        value: 'eqbookid',
        label: '==',
    },
    eqBookName: {
        value: 'eqbookname',
        label: '==',
    },
    ne: {
        value: 'ne',
        label: '!=',
    },
    neBookName: {
        value: 'nebookname',
        label: '!=',
    },
    lt: {
        value: 'lt',
        label: '<',
    },
    le: {
        value: 'le',
        label: '<=',
    },
    gt: {
        value: 'gt',
        label: '>',
    },
    ge: {
        value: 'ge',
        label: '>=',
    },
    mappedBy: {
        value: 'mappedBy',
        label: 'Mapped by',
    },
    inBookId: {
        value: 'inBookId',
        label: 'in',
    },
};

const ruleableOperators = [operatorsList.eq, operatorsList.contains, operatorsList.notContains];

const all = Object.entries(operatorsList);

const isFilterRulable = (filter, operator = {}) =>
    filter.ruleable && ruleableOperators.some(o => o.value === (operator.value || filter.operator.value));

export { operatorsList, all, isFilterRulable, escapeSpecialChars };
