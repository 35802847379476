export enum ProcedureType {
    Procedure = 'Procedure',
    PositionStatement = 'Position statement',
    Bulletin = 'Bulletin',
}

export const ProcedureTypeToNumberValue: Record<ProcedureType, number> = {
    [ProcedureType.Procedure]: 1,
    [ProcedureType.PositionStatement]: 2,
    [ProcedureType.Bulletin]: 3,
};
